import React, { useState } from "react"
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GUESTPOST } from 'helpers/api'

function ForgotPassword() {
	const { t } = useTranslation();
	const history = useHistory();

	const [showActivationCode, setShowActivationCode] = useState(true)
	const [user, setUser] = useState({
		ownerLogin: "",
		key: "",
		newPassword: "",
		repeatPassword: ""
	})

	async function getActivationCode() {
		await GUESTPOST('/services/uaa/api/account/reset-password/init', user, { loader: true })
		setShowActivationCode(!showActivationCode)
	}

	async function changePassword() {
		const response = await GUESTPOST('/services/uaa/api/account/reset-password/finish', user, { loader: true })
		if (response) {
			toast.success(t('SUCCESS') + ': ' + t('DATA_SUCCESFULLY_CHANGED'))
			history.push("/login")
		}
	}

	return (
		<>
			<div className="authentication-bg">
				<div className="account-pages h-100 vertical-center">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-md-8 col-lg-6 col-xl-4">

								<div className="auth-card">
									<div className="text-center my-2">
										<h3 className="text-white">{t('RECOVER_YOUR_PASSWORD')}</h3>
									</div>
									{showActivationCode ?
										<div className="p-2">
											<input className="login-input"
												autoComplete="off"
												name="ownerLogin"
												value={user.ownerLogin}
												placeholder={t('LOGIN')}
												onChange={(e) => setUser({ ...user, 'ownerLogin': e.target.value })} />

											<div className="text-center">
												<button type="button" className="login-button"
													onClick={getActivationCode}
												>
													{t('GET_SMS_CODE')}
												</button>
											</div>
										</div>
										:
										<div className="p-2">
											<input className="login-input"
												autoComplete="off"
												name="key"
												value={user.key}
												placeholder={t('ACTIVATION_CODE')}
												onChange={(e) => setUser({ ...user, 'key': e.target.value })} />

											<input className="login-input"
												autoComplete="off"
												name="newPassword"
												value={user.newPassword}
												placeholder={t('NEW_PASSWORD')}
												onChange={(e) => setUser({ ...user, 'newPassword': e.target.value })} />

											<input className="login-input"
												autoComplete="off"
												name="repeatPassword"
												value={user.repeatPassword}
												placeholder={t('CONFIRM_PASSWORD')}
												onChange={(e) => setUser({ ...user, 'repeatPassword': e.target.value })} />

											<div className="text-center">
												<button type="button" className="login-button"
													onClick={changePassword}
												>
													{t('CHANGE_PASSWORD')}
												</button>
											</div>
										</div>
									}

									<div className="mt-2 text-center">
										<Link to="/login" className="fw-medium text-white">{t('LOGGING_IN')}</Link>
									</div>
								</div>
							</div>
						</div>
					</div>

					<ul className="circles">
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default ForgotPassword
