const skeleton = function (state = false, action) {
  switch (action.type) {
    case "SHOW_SKELETON":
      return true;
    case "HIDE_SKELETON":
      return false;
    default:
      return false;
  }
};

export default skeleton