import React, { useState } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next'

// Reactstrap
import { Modal, Dropdown } from 'react-bootstrap'

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";

import logoIcon from "../../assets/images/logo_icon.svg";

import { POST } from 'helpers/api'
import { clearTemporaryStorage } from 'helpers/helpers'

function Header() {
	const { t } = useTranslation()
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch()

	const [modalLogout, setModalLogout] = useState(false)
	const [showDropdown, setShowDropdown] = useState(false)

	function toggleFullscreen() {
		if (
			!document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	function handleMouseOver() {
		setShowDropdown(true)
	}

	function handleMouseOut() {
		setShowDropdown(false)
	}

	function tToggle() {
		var body = document.body;
		body.classList.toggle("vertical-collpsed");
		body.classList.toggle("sidebar-enable");
	}

	async function logout() {
		await POST('/auth/logout')
		dispatch({ type: 'USER_LOGGED_OUT', payload: null })
		clearTemporaryStorage()
		history.push('/login')
	}

	return (
		<>
			<header id="page-topbar">
				<div className="navbar-header">
					<div className="d-flex align-items-center h-100">
						<div className="navbar-brand-box">
							<Link to="/" className="logo logo-dark">
								<span className="logo-sm">
									<img src={logoIcon} alt="" height="22" />
								</span>
								<span className="logo-lg">
									<div className="logo-style">Uget</div>
								</span>
							</Link>

							<div className={"custom-dropdown ms-3 " + (showDropdown ? 'active' : '')}
								onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
								<div className="custom-dropdown-title">
									{location.pathname.includes('dashboard') &&
										<>Главная</>
									}
									{location.pathname.includes('owner') &&
										<>Админ</>
									}
									{location.pathname.includes('pos') &&
										<>Торговые точки</>
									}
									<i className="uil-angle-down ps-2 fz-28"></i>
								</div>
								<div className="custom-dropdown-items-back">
									<div className="custom-dropdown-items"
										onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
										<Link to="/dashboard">
											<div className="custom-dropdown-item">
												Главная
											</div>
										</Link>
										<Link to="/owners">
											<div className="custom-dropdown-item">
												Админ
											</div>
										</Link>
										<Link to="/poses">
											<div className="custom-dropdown-item">
												Торговые точки
											</div>
										</Link>
									</div>
								</div>
							</div>
						</div>

						<button
							type="button"
							onClick={() => {
								tToggle();
							}}
							className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
							id="vertical-menu-btn"
						>
							<i className="uil-bars fz-20" />
						</button>
					</div>

					<div className="d-flex">

						<LanguageDropdown />

						<Dropdown className="d-none d-lg-inline-block ms-1">
							<button
								type="button"
								onClick={() => {
									toggleFullscreen();
								}}
								className="btn header-item noti-icon waves-effect"
								data-toggle="fullscreen"
							>
								<i className="uil-minus-path"></i>
							</button>
						</Dropdown>
						<div className="d-lg-inline-block ms-1 dropdown" onClick={() => setModalLogout(true)}>
							<button type="button" className="btn header-item noti-icon waves-effect">
								<div className="d-flex">
									<div className="vertical-center me-2">
										{JSON.parse(localStorage.getItem('authUser')).username}
									</div>
									<i className="uil-sign-out-alt"></i>
								</div>
							</button>
						</div>

					</div>
				</div>
			</header>

			<Modal show={modalLogout} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalLogout(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('LOGOUT')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('ARE_YOU_SURE_YOU_WANT_LOGOUT')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setModalLogout(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => logout()}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Header
