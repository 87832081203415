import { combineReducers } from "redux"

import Layout from "./layout/reducer"
import loader from "./loader/reducer"
import skeleton from "./skeleton/reducer"
import settings from "./settings/reducer"
import transferData from "./transferData/reducer"
import report from "./report/reducer"


const allReducers = combineReducers({
  Layout,
  loader,
  skeleton,
  settings,
  transferData,
  report,
})

export const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer
