import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import DashboardIndex from "../pages/dashboard/Index"

import PosGroupIndex from "../pages/posGroup/Index"
import PosGroupCreateUpdate from "../pages/posGroup/CreateUpdate"

import PosIndex from "../pages/pos/Index"
import PosCreateUpdate from "../pages/pos/CreateUpdate"

import Owners from "../pages/owners/Index"
import Cashiers from "../pages/cashiers/Index"
import Comments from "../pages/comments/Index"
import Notifications from "../pages/notifications/Index"
import posesIndex from "../pages/poses/Index"

// Authentication related pages
import Login from "../pages/authentication/Login"
import ForgotPassword from "../pages/authentication/ForgotPassword"

const userRoutes = [
	{ path: "/dashboard", component: DashboardIndex },

	{ path: "/poses", component: posesIndex },
	{ path: "/pos/create", component: PosCreateUpdate },
	{ path: "/pos/update/:id", component: PosCreateUpdate },

	{ path: "/pos-group", component: PosGroupIndex },
	{ path: "/pos-group-create", component: PosGroupCreateUpdate },
	{ path: "/pos-group/:id", component: PosGroupCreateUpdate },

	{ path: "/pos-group/pos/:id", component: PosIndex },
	{ path: "/pos-group/pos/create/:posGroudId", component: PosCreateUpdate },
	{ path: "/pos-group/pos/:posGroudId/:id", component: PosCreateUpdate },
	{ path: "/cashiers", component: Cashiers },
	{ path: "/notifications", component: Notifications },
	{ path: "/comments", component: Comments },
	{ path: "/owners", component: Owners },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
	// 404 route not found
	{ path: "*", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgotPassword },
]

export { userRoutes, authRoutes }