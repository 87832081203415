export let DayOfTheWeek = [
	{
		id: 1,
		nameru: 'Понедельник',
		nameuzLatnUZ: 'Dushanba',
		nameuzCyrlUZ: 'Душанба',
	},
	{
		id: 2,
		nameru: 'Вторник',
		nameuzLatnUZ: 'Seshanba',
		nameuzCyrlUZ: 'Сешанба',
	},
	{
		id: 3,
		nameru: 'Среда',
		nameuzLatnUZ: 'Chorshanba',
		nameuzCyrlUZ: 'Чоршанба',
	},
	{
		id: 4,
		nameru: 'Четверг',
		nameuzLatnUZ: 'Payshanba',
		nameuzCyrlUZ: 'Пайшанба',
	},
	{
		id: 5,
		nameru: 'Пятница',
		nameuzLatnUZ: 'Juma',
		nameuzCyrlUZ: 'Жума',
	},
	{
		id: 6,
		nameru: 'Суббота',
		nameuzLatnUZ: 'Shanba',
		nameuzCyrlUZ: 'Шанба',
	},
	{
		id: 7,
		nameru: 'Воскресенье',
		nameuzLatnUZ: 'Yakshanba',
		nameuzCyrlUZ: 'Якшанба',
	}
]