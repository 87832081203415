const initialState = {
	'reportTab': 1,
	'reportSelectedClient': {},
	'reportSelectedOrganization': {},
}

const settings = function (state = initialState, action) {
	switch (action.type) {
		case "SET_REPORT_TAB":
			return { ...state, 'reportTab': action.payload };
		case "SET_REPORT_SELECTED_CLIENT":
			return { ...state, 'reportSelectedClient': action.payload };
		case "SET_REPORT_SELECTED_ORGANIZATION":
			return { ...state, 'reportSelectedOrganization': action.payload };
		default:
			return state;
	}
};

export default settings