
import ru from "../assets/images/flags/ru.png"
import uzCyrl from "../assets/images/flags/uz-Cyrl-UZ.png"
import uzLatn from "../assets/images/flags/uz-Latn-UZ.png"

const languages = {
  ru: {
    label: "ru",
    i18n: "RUSSIAN",
    flag: ru,
  },
	uzCyrl: {
    label: "uz-Cyrl-UZ",
		i18n: "UZBEK_CYRL",
    flag: uzCyrl,
  },
	uzLatn: {
    label: "uz-Latn-UZ",
		i18n: "UZBEK_LATN",
    flag: uzLatn,
  },
}

export default languages
