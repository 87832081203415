const initialState = {
	'documentInProductList': []
}

const transferData = function (state = initialState, action) {
	switch (action.type) {
		case "SET_DOCUMENT_IN_PRODUCT_LIST":
			return { ...state, 'documentInProductList': action.payload };
		default:
			return state;
	}
};

export default transferData